import Features from "components/common/Features";
import InfoSlider from "components/common/InfoSlider";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import TryRhombusStripe2 from "components/common/TryRhombusStripe2";
import GlobalLayout from "components/page/GlobalLayout";
import AboutSection from "components/webinar/updated/AboutSection";
import WebinarHeader from "components/webinar/updated/WebinarHeader";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function TileDemo() {
  const renderContent = data => {
    const renderPromoText = () => {
      return (
        <div>
          <p>Register today and get a free Tile Slim!*</p>
          <p style={{ fontSize: "12px" }}>*Terms &amp; Conditions apply</p>
        </div>
      );
    };
    const pageData = {
      header: {
        preTitle: "Live Demo",
        title: "Next-Generation Cloud Video Security",
        formId: "46076269-d60a-4115-83f3-0e351b8df596",
        formTitle: "Request Demo",
        image: data.headerImage,
      },

      aboutSection: {
        title: "About the Rhombus Demo",
        description:
          "See why Rhombus is the leading physical security solution for organizations of all sizes. During your demo, you’ll learn:",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        speakers: null,
        image: data.learnImage,
        promoText: renderPromoText(),
      },
      benefits: {
        list: [
          {
            title: "Enjoy an all-in-one solution that grows with your needs.",
            tabTitle: "Infinite Scalability",

            description:
              "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
            image: data.benefit1,
          },
          {
            tabTitle: "Seamless Deployment",
            title: "Register plug-and-play devices in as little as 5 minutes.",
            description:
              "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
            image: data.benefit2,
          },
          {
            tabTitle: "Proactive Alerts",
            title:
              "Stay in the know with real-time alerts for faces, vehicles, and more.",
            description:
              "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
            image: data.benefit4,
          },
          {
            tabTitle: "Easy Investigations",
            title:
              "Save time with multi-camera investigations and smart search.",
            description:
              "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "handshake",
          title: "Trusted Partner",
          p:
            "Our mission is to build a true partnership and provide simple, smart, and powerful solutions that fit your unique needs.",
        },
        {
          icon: data.icon2,
          iconAlt: "check mark",
          title: "Industry Veterans",
          p:
            "Since pioneering cloud security cameras, we’ve continued to create new, innovative ways to serve our customers. ",
        },
        {
          icon: data.icon3,
          iconAlt: "security",
          title: "Secure by Default",
          p:
            "Rhombus’ track record speaks for itself with zero-trust security framework and zero breaches.",
        },
        {
          icon: data.icon4,
          iconAlt: "support",
          title: "World-Class Support",
          p:
            "We guarantee you’ll receive a phenomenal experience from a team dedicated to your success.",
        },
      ],
    };
    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>
            Rhombus - Enterprise Video Security & Surveillance Webinars
          </title>
          <meta
            name="description"
            content="No DVR/NVRs required. Learn about modern cloud security cameras and environmental sensors in the next live webinar."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <WebinarHeader data={pageData.header} imageCenter />
        <AboutSection data={pageData.aboutSection} />
        <InfoSlider
          data={pageData.benefits.list}
          title="The Benefits of Rhombus"
          color="var(--nuetral-100)"
        />
        <Features data={pageData.features} title="Rhombus Platform Features" />
        <IndustryLeadersCards color="var(--nuetral-100)" />
        <TryRhombusStripe2 />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/img/tile-demo-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      learnImage: file(
        relativePath: {
          eq: "components/landing-pages/img/rhombus-products-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/trust.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/demo/img/secure-by-default.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(relativePath: { eq: "components/demo/img/headset.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
