import PageContent from "components/page/PageContent";
import React from "react";
import SlideInContainer from "./SlideInContainer";
import RoundLink from "./RoundLink";
import { css } from "linaria";

const sectionContainer = css`
  background: var(--nuetral-100);
  background: linear-gradient(270deg, #e3f8fb 0%, #cde1f8 100%);
  padding: 90px 0;
`;

const bannerContainer = css`
  width: 100%;
  gap: 25px;
  > div div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 20px;
  max-width: 665px;
`;

const headerClass = css`
  background-image: linear-gradient(to left, var(--teal-500), var(--blue-500));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 48px;
  line-height: 1;
`;

const subtitleClass = css`
  display: block;
  @media (max-width: 690px) {
    margin-bottom: 1rem;
  }
`;

const buttonStyle = css``;

const TryRhombusStripe2 = ({
  title = "See Rhombus for Yourself",
  blurb = "Request a demo with our team today and see why industry leaders choose Rhombus",
  buttonText = "Get Demo",
}) => {
  const handleScrollToTop = () => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  };
  return (
    <section className={sectionContainer}>
      <PageContent>
        <div className={bannerContainer}>
          <SlideInContainer>
            <div className={textContainer}>
              <h2 className={headerClass}>{title}</h2>
              <p className={subtitleClass}>{blurb}</p>
              <RoundLink
                appearance="gradientFill"
                className={buttonStyle}
                onClick={handleScrollToTop}
              >
                {buttonText}
              </RoundLink>
            </div>
          </SlideInContainer>
        </div>
      </PageContent>
    </section>
  );
};

export default TryRhombusStripe2;
